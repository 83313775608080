<template>
  <v-carousel class="elevation-0" style="margin-bottom: 100px;" hide-controls hide-delimiters height="70">
    <v-carousel-item :key="index" v-for="(imgs,index) in carouselImages">
      <v-layout justify-space-around row>
        <div :key="img" v-for="img in imgs">
          <img :width="carouselImageWidth" height="70" :src="require(`../../public/img/carousel-imgs/${img}.png`)" :alt="img">
        </div>
      </v-layout>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import _chunk from 'lodash/chunk';

export default {
  name: "AppCarousel",
  data(){
    return{
      images:[
          'telenor',
          'heineken',
          'samsung',
          'huawei',
          'realme',
          'dksh',
          'reuters',
          'mega',
          'wavemoney',
          'cbbank',
          'kbz',
          'awba',
      ]
    }
  },
  computed:{
    carouselImages(){
      return _chunk(this.images, 4)
    },
    carouselImageWidth(){
      return this.$vuetify.breakpoint.smAndDown ? 75 : 100;
    }
  }
}
</script>

<style scoped>

</style>
