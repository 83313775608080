<template>
    <svg id="header-logo" style="enable-background:new 0 0 8192 1912;" version="1.1" viewBox="0 0 8192 1912"
         x="0px" xml:space="preserve"
         xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
        <path id="b" d="M2210.2,391.2c-57.5-34.5-122.7-49.9-191.7-49.9c-57.5,0-115,11.5-164.9,38.3c-49.9,23-92,61.4-122.7,103.5 V76.7c0-30.7-23-53.7-49.9-53.7h-3.8c-15.3-3.8-26.8,3.8-38.3,15.3c-7.7,7.7-15.3,23-15.3,38.3v310.6c0,3.8,0,7.7,0,11.5v326 c0,69,15.3,138.1,49.9,195.6c34.5,57.5,80.5,107.4,138.1,138.1c122.7,69,268.4,69,391.2,0c57.5-34.5,107.4-80.5,138.1-138.1 c65.2-122.7,65.2-272.3,0-395C2313.7,471.7,2267.7,421.8,2210.2,391.2z M2256.2,870.5c-23,42.2-57.5,80.5-99.7,103.5 c-42.2,26.8-92,38.3-141.9,38.3c-49.9,0-99.7-11.5-141.9-38.3s-76.7-61.4-99.7-103.5c-26.8-46-38.3-95.9-38.3-145.7 c0-15.3,0-26.8,0-34.5c0-3.8,0-3.8,0-7.7c7.7-69,42.2-134.2,95.9-176.4c49.9-46,118.9-69,187.9-69c49.9,0,99.7,11.5,141.9,38.3 c42.2,23,76.7,61.4,99.7,103.5C2302.2,671.1,2302.2,782.3,2256.2,870.5L2256.2,870.5z"/>
        <path id="i" d="M2681.9,345.2c-26.8,0-53.7,23-53.7,49.9v3.8v651.9c0,26.8,19.2,53.7,49.9,53.7h3.8c15.3,0,26.8-3.8,38.3-15.3 c7.7-11.5,15.3-23,15.3-38.3V398.8c0-15.3-3.8-26.8-15.3-38.3C2708.7,349,2697.2,345.2,2681.9,345.2z"/>
        <path id="n" d="M3540.9,379.7c-53.7-26.8-115-42.2-176.4-42.2c-49.9,0-95.9,7.7-141.9,30.7c-42.2,19.2-80.5,46-111.2,80.5 v-49.9c0-15.3-3.8-26.8-15.3-38.3s-23-15.3-38.3-15.3s-26.8,3.8-38.3,15.3c-11.5,11.5-15.3,23-15.3,38.3v651.9 c0,15.3,3.8,26.8,15.3,38.3c11.5,7.7,23,15.3,38.3,15.3s26.8-3.8,38.3-15.3c11.5-11.5,15.3-23,15.3-38.3V640.4 c0-38.3,11.5-72.9,30.7-103.5c23-30.7,53.7-57.5,88.2-72.9c38.3-19.2,80.5-26.8,122.7-26.8c65.2-3.8,130.4,19.2,180.2,65.2 c49.9,49.9,72.9,118.9,69,187.9v360.5c0,15.3,3.8,26.8,15.3,38.3s23,15.3,38.3,15.3c26.8,0,53.7-23,53.7-49.9v-3.8V690.3 c3.8-65.2-11.5-134.2-46-191.7C3636.8,448.7,3594.6,406.5,3540.9,379.7z"/>
        <path id="d" d="M4676,76.7c0-15.3-3.8-26.8-15.3-38.3s-23-15.3-38.3-15.3c-30.7,0-53.7,23-53.7,49.9v3.8v406.5 c-30.7-46-72.9-80.5-122.7-103.5c-49.9-26.8-107.4-38.3-164.9-38.3c-134.2-3.8-260.8,69-326,187.9c-34.5,61.4-49.9,126.6-49.9,195.6 s15.3,138.1,49.9,195.6c34.5,57.5,80.5,107.4,138.1,138.1c122.7,69,272.3,69,391.2,0c57.5-34.5,107.4-80.5,138.1-138.1 c34.5-61.4,49.9-126.6,49.9-195.6v-326c0-3.8,0-7.7,0-11.5L4676,76.7z M4534.1,870.5c-49.9,88.2-141.9,141.9-241.6,141.9 c-49.9,0-99.7-11.5-145.7-38.3c-42.2-26.8-76.7-61.4-99.7-103.5c-49.9-92-49.9-203.3,0-295.3c23-42.2,57.5-80.5,99.7-103.5 c42.2-26.8,92-38.3,145.7-38.3c69,0,134.2,23,187.9,69c49.9,46,84.4,111.2,92,176.4c0,3.8,0,3.8,0,7.7c0,7.7,0,19.2,0,34.5 C4568.7,774.7,4557.2,824.5,4534.1,870.5z"/>
        <path id="e" d="M5431.5,387.3c-53.7-30.7-118.9-49.9-180.2-49.9c-65.2,0-130.4,15.3-187.9,49.9 c-53.7,34.5-99.7,80.5-130.4,138.1c-30.7,61.4-46,130.4-46,199.4s15.3,138.1,49.9,199.4c30.7,57.5,80.5,103.5,138.1,138.1 c61.4,34.5,130.4,49.9,199.4,49.9c46,0,92-7.7,134.2-26.8c42.2-15.3,80.5-38.3,115-65.2c11.5-7.7,15.3-19.2,15.3-30.7 c0-26.8-23-46-49.9-49.9c-15.3,0-26.8,3.8-38.3,15.3c-23,19.2-49.9,34.5-80.5,42.2c-30.7,11.5-65.2,15.3-99.7,15.3 c-149.6,3.8-272.3-107.4-283.8-256.9h559.9c15.3,0,26.8-3.8,38.3-11.5c7.7-7.7,15.3-23,15.3-34.5c0-65.2-15.3-130.4-42.2-191.7 C5527.4,464,5485.2,418,5431.5,387.3L5431.5,387.3z M4990.5,671.1c7.7-65.2,38.3-126.6,84.4-172.6c46-42.2,111.2-69,176.4-65.2 c65.2-3.8,126.6,23,168.7,65.2c46,46,72.9,107.4,76.7,172.6H4990.5z"/>
        <path id="z" d="M6305.9,1008.6h-418l452.5-575.2c7.7-11.5,11.5-23,11.5-34.5c3.8-23-11.5-46-34.5-49.9c-3.8,0-7.7,0-11.5,0 H5792c-26.8,0-49.9,23-49.9,49.9s23,49.9,49.9,49.9h421.8l-456.4,575.2c-7.7,11.5-11.5,23-11.5,34.5c-3.8,23,11.5,46,34.5,49.9 c3.8,0,7.7,0,11.5,0h513.9c26.8-3.8,46-26.8,46-53.7C6348.1,1027.8,6328.9,1008.6,6305.9,1008.6z"/>
        <g>
            <path id="insight" class="st1" d="M4300.2,1760.2c-3.8-3.8-7.7-7.7-7.7-15.3v-249.3c0-11.5,7.7-23,19.2-23l0,0c3.8,0,11.5,3.8,15.3,7.7 c3.8,3.8,3.8,7.7,3.8,15.3v249.3c0,3.8,0,11.5-3.8,15.3c0,3.8-7.7,3.8-11.5,3.8C4311.7,1764.1,4304.1,1764.1,4300.2,1760.2z"/>
            <path id="insight" class="st1" d="M4641.5,1488c19.2,11.5,34.5,26.8,46,46c11.5,23,19.2,46,15.3,72.9v138.1c0,3.8-3.8,11.5-7.7,15.3 c0,3.8-3.8,3.8-11.5,3.8c-3.8,0-11.5,0-15.3-7.7c-3.8-3.8-7.7-7.7-7.7-15.3V1603c0-26.8-7.7-53.7-26.8-72.9 c-19.2-15.3-42.2-26.8-69-26.8c-15.3,0-30.7,3.8-46,11.5c-15.3,7.7-26.8,15.3-34.5,26.8c-7.7,11.5-11.5,23-11.5,38.3v157.2 c0,3.8,0,11.5-7.7,15.3c0,11.5-3.8,11.5-7.7,11.5c-3.8,0-11.5,0-15.3-3.8c-3.8-3.8-7.7-7.7-7.7-15.3v-249.3c0-3.8,0-11.5,7.7-15.3 c3.8-3.8,7.7-7.7,15.3-7.7c3.8,0,11.5,0,15.3,7.7c3.8,3.8,7.7,7.7,7.7,15.3v19.2c11.5-11.5,26.8-23,42.2-30.7 c15.3-7.7,34.5-11.5,53.7-11.5C4595.5,1468.8,4618.5,1476.5,4641.5,1488z"/>
            <path id="insight" class="st1" d="M4829.4,1756.4c-19.2-7.7-34.5-15.3-49.9-30.7c-7.7-7.7-7.7-19.2,0-26.8l3.8-3.8c3.8-3.8,7.7-3.8,11.5-3.8 c7.7,0,11.5,3.8,15.3,7.7c7.7,11.5,19.2,19.2,34.5,23c15.3,3.8,30.7,7.7,46,7.7c19.2,0,38.3-3.8,57.5-11.5 c11.5-7.7,19.2-19.2,19.2-34.5c0-11.5-7.7-26.8-19.2-30.7c-19.2-11.5-38.3-15.3-61.4-19.2c-26.8-3.8-53.7-15.3-76.7-30.7 c-15.3-11.5-26.8-30.7-26.8-49.9c0-23,11.5-46,30.7-61.4c23-15.3,49.9-23,76.7-23c23,0,42.2,3.8,61.4,11.5 c15.3,7.7,30.7,19.2,42.2,34.5c3.8,3.8,3.8,7.7,3.8,11.5c0,7.7-3.8,11.5-7.7,15.3c-3.8,3.8-7.7,3.8-11.5,3.8 c-7.7,0-15.3-3.8-19.2-7.7c-19.2-19.2-46-30.7-72.9-30.7c-15.3,0-34.5,3.8-46,11.5c-11.5,7.7-15.3,19.2-15.3,30.7s7.7,23,15.3,30.7 c19.2,11.5,38.3,15.3,57.5,19.2c30.7,3.8,57.5,15.3,80.5,30.7c15.3,11.5,23,34.5,23,53.7c0,15.3-3.8,30.7-15.3,42.2 s-26.8,23-42.2,30.7c-19.2,7.7-38.3,11.5-57.5,11.5C4871.6,1767.9,4848.6,1764.1,4829.4,1756.4z"/>
            <path id="insight" class="st1" d="M5086.4,1760.2c-3.8-3.8-7.7-7.7-7.7-15.3v-249.3c0-11.5,7.7-23,23-23l0,0c3.8,0,11.5,3.8,15.3,7.7 c3.8,3.8,3.8,7.7,3.8,15.3v249.3c0,3.8,0,11.5-3.8,15.3c-3.8,3.8-7.7,3.8-15.3,3.8C5097.9,1764.1,5090.2,1764.1,5086.4,1760.2z"/>
            <path id="insight" class="st1" d="M5431.5,1491.8c23,11.5,38.3,30.7,53.7,53.7c11.5,23,19.2,49.9,19.2,76.7v107.4c0,26.8-7.7,53.7-19.2,76.7 c-26.8,46-76.7,76.7-126.6,72.9c-26.8,0-49.9-3.8-72.9-15.3c-19.2-11.5-38.3-26.8-53.7-42.2c-3.8-3.8-3.8-7.7-3.8-11.5 s3.8-11.5,7.7-15.3c3.8-3.8,7.7-3.8,11.5-3.8s7.7,0,11.5,3.8c3.8,0,3.8,3.8,7.7,7.7c11.5,15.3,23,23,42.2,30.7 c19.2,7.7,34.5,11.5,53.7,11.5c38.3,0,72.9-19.2,92-53.7c11.5-19.2,15.3-38.3,15.3-61.4v-23c-11.5,19.2-26.8,34.5-46,46 c-19.2,11.5-42.2,19.2-65.2,15.3c-26.8,0-49.9-7.7-72.9-19.2c-23-11.5-38.3-30.7-49.9-53.7c-11.5-23-19.2-49.9-19.2-76.7 s7.7-53.7,19.2-76.7c11.5-23,30.7-42.2,53.7-53.7C5328,1465,5385.5,1465,5431.5,1491.8L5431.5,1491.8z M5408.5,1718.1 c15.3-7.7,30.7-23,38.3-38.3c19.2-34.5,19.2-80.5,0-115c-7.7-15.3-23-30.7-38.3-38.3c-15.3-11.5-34.5-15.3-53.7-15.3 s-38.3,3.8-57.5,15.3c-15.3,7.7-30.7,23-38.3,38.3c-19.2,34.5-19.2,80.5,0,115c7.7,15.3,23,30.7,38.3,38.3 c15.3,11.5,34.5,15.3,57.5,15.3C5374,1729.6,5393.2,1725.7,5408.5,1718.1z"/>
            <path id="insight" class="st1" d="M5784.3,1488c19.2,11.5,34.5,26.8,46,46c11.5,23,19.2,46,15.3,72.9v138.1c0,11.5-11.5,19.2-23,19.2 c-3.8,0-11.5-3.8-15.3-3.8c-3.8-3.8-7.7-7.7-7.7-15.3v-138.1c0-26.8-7.7-53.7-26.8-72.9c-19.2-15.3-42.2-26.8-69-26.8 c-15.3,0-30.7,3.8-46,11.5c-15.3,7.7-26.8,15.3-34.5,26.8s-11.5,23-11.5,38.3v157.2c0,3.8,0,11.5-3.8,15.3 c-3.8,3.8-7.7,3.8-15.3,3.8c-3.8,0-11.5,0-15.3-3.8s-7.7-7.7-3.8-15.3v-375.8c0-3.8,3.8-11.5,3.8-15.3c3.8-3.8,7.7-7.7,15.3-7.7 c3.8,0,11.5,0,15.3,7.7c3.8,3.8,7.7,7.7,3.8,15.3v141.9c11.5-11.5,26.8-23,42.2-30.7c15.3-7.7,34.5-11.5,53.7-11.5 C5738.3,1468.8,5765.2,1476.5,5784.3,1488z"/>
            <path id="insight" class="st1" d="M5956.9,1349.9L5956.9,1349.9c11.5,0,15.3,3.8,19.2,11.5c0,0,0,3.8,0,7.7v99.7h72.9c7.7,0,15.3,3.8,19.2,11.5 c0,3.8,0,3.8,0,3.8v3.8c0,7.7-3.8,11.5-11.5,15.3c-3.8,0-3.8,0-7.7,0h-76.7v164.9c0,15.3,3.8,30.7,11.5,42.2 c7.7,11.5,15.3,19.2,26.8,19.2c3.8,0,7.7,0,15.3,0h19.2c11.5,0,15.3,3.8,19.2,7.7c3.8,3.8,3.8,7.7,3.8,11.5l0,0 c0,7.7-3.8,15.3-15.3,15.3c-3.8,0-3.8,0-7.7,0h-23c-26.8,0-53.7-11.5-72.9-34.5c-11.5-15.3-19.2-38.3-19.2-57.5v-306.8 c0-7.7,7.7-15.3,15.3-19.2L5956.9,1349.9z"/>
            <path id="insight" class="st1" d="M6183.2,1756.4c-19.2-7.7-34.5-15.3-49.9-30.7c-3.8-3.8-3.8-7.7-3.8-11.5c0-7.7,3.8-11.5,7.7-15.3 s7.7-3.8,15.3-3.8s11.5,3.8,15.3,7.7c7.7,11.5,19.2,19.2,34.5,23c15.3,3.8,30.7,7.7,46,7.7c19.2,0,38.3-3.8,57.5-11.5 c11.5-7.7,19.2-19.2,19.2-34.5c0-11.5-7.7-26.8-19.2-30.7c-19.2-11.5-38.3-15.3-57.5-19.2c-26.8-3.8-53.7-15.3-76.7-30.7 c-15.3-11.5-26.8-30.7-26.8-49.9c0-23,11.5-46,30.7-61.4c23-15.3,49.9-23,76.7-23c23,0,42.2,3.8,61.4,11.5 c15.3,7.7,30.7,19.2,42.2,34.5c3.8,3.8,3.8,7.7,3.8,11.5c0,7.7-3.8,11.5-7.7,15.3s-7.7,3.8-11.5,3.8c-7.7,0-15.3-3.8-19.2-7.7 c-19.2-19.2-46-30.7-72.9-30.7c-15.3,0-34.5,3.8-46,11.5c-11.5,7.7-19.2,19.2-19.2,30.7s7.7,23,15.3,30.7 c19.2,11.5,38.3,15.3,57.5,19.2c30.7,3.8,57.5,15.3,80.5,30.7c15.3,11.5,23,34.5,23,53.7c0,15.3-3.8,30.7-15.3,42.2 s-26.8,23-42.2,30.7c-19.2,7.7-38.3,11.5-57.5,11.5C6225.4,1767.9,6202.3,1764.1,6183.2,1756.4z"/>
        </g>
    </svg>
</template>

<script>
	export default {
		name: "AppBindezHeaderLogo"
	}
</script>

<style scoped>
    .st0 {
        fill: #26A689;
    }

    .st1 {
        fill: #627780;
    }

    #header-logo{
      height: 136px;
    }
</style>
