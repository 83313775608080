<template>
    <div>
        <div id="lp-container" class="lp-container" :class="{loading: isLoading, loaded: !isLoading}">
            <header class="lp-header" :class="{'loading-style': isLoading, 'loaded-style': !isLoading}">
                <h1 v-show="iconStatus" class="lp-logo">
                    <AppBindezHeaderLogo/>
                </h1>
                <v-layout row fill-height justify-end align-end pb-5>
                    <v-flex text-xs-center pb-5>
                        <v-progress-circular
                                :size="40"
                                :width="4"
                                color="white"
                                v-if="isLoading"
                                indeterminate
                        >
                        </v-progress-circular>
                    </v-flex>
                </v-layout>
            </header>
            <section class="lp-main">
                <div class="lp-main_content">
                    <h2>Listen to your customers in Myanmar</h2>
                    <h3>
                        Turn voices into actionable insights<br> with the best social listening and media monitoring tool.
                    </h3>
                </div>
                <div class="lp-main_asset iphone lp-button">
                    <a data-modal="modalForm" @click="requestDemo(true)" class="form-trigger btn btn-lg teal text-center">Request Demo</a>
                </div>
                <div class="lp-main_asset laptop"><img :src="require('../../public/img/banner/banner_mac.png')" class="img-responsive" alt="">
                </div>
                <div class="lp-main_asset ipad"><img :src="require('../../public/img/banner/banner_ipad.png')" class="img-responsive" alt="">
                </div>
            </section>
            <!-- container -->
            <!-- Why Insight -->
            <section class="lp-customer">
                <div class="lp-customer_header">
                  <AppCarousel/>
                  <h2 id="whyinsights">WHY INSIGHTS</h2>
                    <p>
                        Invest wisely in a product specifically designed for Myanmar market.<br> Never pay for the things you don't need.
                    </p>
                </div>
            </section>
            <div class="header-bottom-area">
                <!-- header bottom area -->
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-item-01  wow zoomIn"
                                 style="visibility: visible; animation-name: zoomIn;">
                                <!-- single feature item 01 -->
                                <div class="icon">
                                    <i class="icon-real-time"></i>
                                </div>
                                <div class="content">
                                    <h4 class="title">Language Accuracy</h4>
                                    <p><strong>Burmese is one of the most complex languages.</strong><br><br>
                                        We developed the best algorithms designed to analyze Burmese contents.
                                    </p>
                                </div>
                            </div><!-- //. single feature item 01 -->
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-item-01  wow zoomIn"
                                 style="visibility: visible; animation-name: zoomIn;">
                                <!-- single feature item 01 -->
                                <div class="icon">
                                    <i class="icon-data"></i>
                                </div>
                                <div class="content">
                                    <h4 class="title">Data Coverage</h4>
                                    <p><strong>
                                        Never miss important conversations.
                                    </strong><br><br>
                                        We monitor the largest  inventory of important social entities in Myanmar.
                                    </p>
                                </div>
                            </div><!-- //. single feature item 01 -->
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-feature-item-01  wow zoomIn"
                                 style="visibility: visible; animation-name: zoomIn;">
                                <!-- single feature item 01 -->
                                <div class="icon">
                                    <i class="icon-burmese"></i>
                                </div>
                                <div class="content">
                                    <h4 class="title">Speed</h4>
                                    <p><strong>AI-Powered Real-time data analysis </strong><br><br>
                                        We automate the entire data analytics process using AI & machine learning.
                                    </p>
                                </div>
                            </div><!-- //. single feature item 01 -->
                        </div>

                    </div>
                </div>
            </div>
            <!-- Why Insight & Buzz Monitor -->
            <section class="lp-customer buzz-monitor">
                <div class="lp-customer_header">
                    <h2 id="features">FEATURES</h2>
                    <p>Empower your team with powerful product functions and level up your decision making process.</p>
                </div>
                <div class="lp-customer_container">
                    <figure class="buzz_monitor-slide_1">
                        <img :src="require('../../public/img/features/sentiment.png')" alt="Real Time" class="img-responsive">
                    </figure>
                    <article class="lp-customer_copy">
                        <h2>Sentiment Analysis</h2>
                        See the most important opinions about your brand posted online by Myanmar people in Burmese. Take advantages of our state of the art Burmese language processing algorithms.
                        <div class="lp-cta">
                            <button @click="requestDemo(true)" class="form-trigger btn btn-large teal" data-modal="modalForm">
                                Request Demo Now
                            </button>
                        </div>
                    </article>
                </div>
            </section>

            <!-- Sentiment Analysis -->
            <section class="lp-customer threedayblinds">
                <div class="lp-customer_container">
                    <figure class="threedayblinds-slide_1">
                        <img :src="require('../../public/img/whinsight/key-words-2.png')" alt="3 Day Blinds" class="img-responsive">
                    </figure>
                    <article class="lp-customer_copy">
                        <h2>Topic and Sentiment Drivers</h2>
                        Understand the context of conversations and what drive the sentiments in no time.<br>
                        Let our AI identify the topics of millions of conversations to save you time spotting the important discussion area.
                        <div class="lp-cta">
                            <button @click="requestDemo(true)" class="form-trigger btn btn-large teal" data-modal="modalForm">
                                Request Demo Now
                            </button>
                        </div>
                    </article>
                </div>
            </section>
            <!-- Competitor Analysis -->
            <section class="lp-customer automated-reporting pb-5">
              <div class="lp-customer_container">
                <figure class="automated-reporting-slide_1">
                  <img :src="require('../../public/img/features/competitor.png')" alt="Real Time" class="img-responsive">
                </figure>
                <article class="lp-customer_copy">
                  <h2>Competitor Analysis</h2>
                  Keep an eye on your competitors, their online media and social media activities and understand
                  the public perception of their brand and products. Compare your brand with theirs.
                  <div class="lp-cta">
                    <button @click="requestDemo(true)" class="form-trigger btn btn-large teal" data-modal="modalForm">
                      Request Demo Now
                    </button>
                  </div>
                </article>
              </div>
            </section>
            <!--==========The Watch==========-->
            <section ref="phoneCall" class="row the-watch see_how" :class="{'in-view':phoneScroll}">
                <div class="container">
                    <div class="row">
                        <div class="col-md-5 col-md-offset-1 text-center the-watch-img wow zoomIn hidden-xs"
                             style="visibility: visible; animation-name: zoomIn; padding-right: 0;">
                                <img
                                        :src="phoneScroll ? require('../../public/img/maps/gmap.png') : require('../../public/img/maps/wmap.png')"
                                        alt="myanmarmap"
                                        class="img-responsive" style="max-height:600px; margin-left:auto;"
                                />
                        </div>
                        <div class="col-md-6 the-watch-features">
                            <div class="row section-header v3 wow fadeIn"
                                 style="visibility: visible; animation-name: fadeIn;">
                                <h2>Just A Phone Call Away</h2>
                                <p>
                                    Our friendly local support team is always just a phone call away whenever you need us.
                                    From technical support to data understanding, our bilangual account managers will provide the most efficient consultations.
                                </p>
                            </div>
                            <ul class="nav">
                                <li class="wow fadeIn" data-wow-delay="0.2s"
                                    style="visibility: visible; animation-delay: 0.2s; animation-name: fadeIn;">Local
                                    Company
                                </li>
                                <li class="wow fadeIn" data-wow-delay="0.4s"
                                    style="visibility: visible; animation-delay: 0.4s; animation-name: fadeIn;">Local
                                    Market Understanding
                                </li>
                                <li class="wow fadeIn" data-wow-delay="0.6s"
                                    style="visibility: visible; animation-delay: 0.6s; animation-name: fadeIn;">Local
                                    Technology
                                </li>
                                <li class="wow fadeIn" data-wow-delay="0.8s"
                                    style="visibility: visible; animation-delay: 0.8s; animation-name: fadeIn;">Inbound
                                    Support
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="swipe"></div>
            </section>
        </div>
        <div class="form-overlay"></div>
    </div>
</template>

<script>

import * as ScrollMagic from 'scrollmagic';
import {TweenMax, TimelineMax} from "gsap";
import {ScrollMagicPluginGsap} from "scrollmagic-plugin-gsap";
import AppBindezHeaderLogo from "../components/StaticAssets/AppBindezHeaderLogo";
import AppCarousel from "@/components/AppCarousel";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
export default {
  name: "Index",
  components: {
    AppBindezHeaderLogo,
    AppCarousel
  },
  mounted() {
    var controller = new ScrollMagic.Controller();
    var buzz_monitorOne = TweenMax.to(".buzz_monitor-slide_1", 10, {x: 100, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".buzz-monitor",
      triggerHook: "onEnter",
      duration: "250%"
    })
        .setTween(buzz_monitorOne)
        .addTo(controller);

    var buzz_monitorTwo = TweenMax.to(".buzz_monitor-slide_2", 10, {x: 100, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".buzz-monitor",
      triggerHook: "onEnter",
      duration: "250%"
    })
        .setTween(buzz_monitorTwo)
        .addTo(controller);


//Sentiment Analysis
    var threedayblindsOne = TweenMax.to(".threedayblinds-slide_1", 10, {x: 90, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".threedayblinds",
      triggerHook: "onEnter",
      duration: "250%"
    })
        .setTween(threedayblindsOne)
        .addTo(controller);

    var threedayblindsTwo = TweenMax.to(".threedayblinds-slide_2", 10, {y: -250, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".threedayblinds",
      triggerHook: "onEnter",
      duration: "300%"
    })
        .setTween(threedayblindsTwo)
        .addTo(controller);


// Competitor Analysis
    var competitorAnalysis = TweenMax.to(".coverage-slide_1", 10, {x: 100, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".competitor-analysis",
      triggerHook: "onEnter",
      duration: "250%"
    })
        .setTween(competitorAnalysis)
        .addTo(controller);


//Smart Data Visualisation
    var smdVisualisation = TweenMax.to(".smd-visualisation-slide_1", 10, {x: 90, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".smd-visualisation",
      triggerHook: "onEnter",
      duration: "250%"
    })
        .setTween(smdVisualisation)
        .addTo(controller);

// Automated Reporting
    var automatedReporting = TweenMax.to(".automated-reporting-slide_1", 10, {x: 100, ease: Linear.easeNone});

    var scene = new ScrollMagic.Scene({
      triggerElement: ".automated-reporting",
      triggerHook: "onEnter",
      duration: "250%"
    })
        .setTween(automatedReporting)
        .addTo(controller);


  },

  computed: {
    isLoading() {
      return this.$attrs.pagesProps.isLoading;
    },

    phoneScroll() {
      return this.$attrs.pagesProps.phoneScroll;
    },

    iconStatus() {
      return this.$attrs.iconStatus;
    }
  },

  methods: {
    requestDemo(val) {
      this.$ua.trackEvent("Landing Page", "click", "Request Demo");
      this.$emit("requestDemo", val)
    }
  }

}
</script>

<style scoped>
    @import '../../public/css/bootstrap.min.css';
    @import '../../public/css/bindez.css';
    @import '../../public/css/bindez-custom2.css';
    @font-face {
        font-family: 'icomoon';
        src: url('../../public/fonts/icomoon.eot');
        src: url('../../public/fonts/icomoon.eot') format('embedded-opentype'),
        url('../../public/fonts/icomoon.ttf') format('truetype'),
        url('../../public/fonts/icomoon.woff') format('woff'),
        url('../../public/fonts/icomoon.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    [class^="icon-"],
    [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-burmese:before {
        content: "\e900";
    }

    .icon-data:before {
        content: "\e901";
    }

    .icon-real-time:before {
        content: "\e902";
    }

    #lp-container {
        background-color: #fff;
    }

    .loaded-style {
        max-height: 230px;
        position: absolute;
    }

    .loading-style {
        min-height: 460px;
        position: fixed;
    }

    .lp-main_asset.lp-button.iphone{
        left: 50%;
        text-align: center;
        width: 220px;
        transform: translateX(-48.5%);
    }
</style>
