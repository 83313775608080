import { render, staticRenderFns } from "./AppCarousel.vue?vue&type=template&id=6cc39d24&scoped=true&"
import script from "./AppCarousel.vue?vue&type=script&lang=js&"
export * from "./AppCarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cc39d24",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/var/www/html/insights/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib'
import { VCarouselItem } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VCarousel,VCarouselItem,VLayout})
